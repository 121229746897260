
@mixin media($width_min: $mediaXS, $width_max: $mediaMAX) {
    @media (min-width: #{$width_min}) and (max-width: $width_max - 1) {
      @content;
    }
}

@mixin mediaXXS() {
    @media (max-width: $mediaXS - 1) {
      @content;
    }
  }
  
  @mixin mediaXS() {
    @media (max-width: $mediaSM - 1) {
      @content;
    }
  }
  
  @mixin mediaSM() {
    @media (max-width: $mediaMD - 1) {
      @content;
    }
  }
  
  @mixin mediaMD() {
    @media (max-width: $mediaLG - 1) {
      @content;
    }
  }
  
  @mixin mediaLG() {
    @media (min-width: #{$mediaLG}) and (max-width: $mediaXL - 1) {
      @content;
    }
  }
  
  @mixin mediaXL() {
    @media (min-width: #{$mediaXL}) {
      @content;
    }
  }