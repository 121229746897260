.article-third-card {
    width: 100%;
    max-width: 373px;

    @include mediaSM {
        max-width: 330px;
    }

    @include mediaXS {
        max-width: 100%;
    }

    &__image {
        cursor: pointer;
        position: relative;
        width: 100%;
        margin-bottom: 14px;

        @include mediaSM {
            margin-bottom: 12px;
        }

        @include mediaXS {
            margin-bottom: 15px;
        }

        img {
            display: block;
            min-height: 200px;
            width: 100%;
            background: #E8E8E8;
            object-fit: cover;
            object-position: center;
        }
    }

    &__content {
        .eyebrow {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.42px;
            opacity: 0.9;
            margin-bottom: 14px;

            @include mediaSM {
                margin-bottom: 12px;
            }

            @include mediaXS {
                margin-bottom: 10px;
                font-size: 12px;
            }
        }

        .link-arrow {
            display: inline-block;
            width: 15px;
            height: 10px !important;
            top: -1px;
        }

        .news-title {
            cursor: pointer;
            font-family: 'Sohne', sans-serif;
            font-weight: 500;
            font-size: 24px;
            line-height: 31px;
            letter-spacing: 0.2px;
            margin-bottom: 9px;

            @include mediaSM {
                font-size: 20px;
                line-height: 28px;
                max-width: 98%;
                margin-bottom: 12px;
            }

            @include mediaXS {
                line-height: 24px;
                letter-spacing: 0.42px;
                max-width: 92%;
                margin-bottom: 9px;
            }
        }

        a {
            font-size: 1.4rem;
            line-height: 1.6rem;
            letter-spacing: 0.42px;
        }
    }
}

.article-third-card__image:hover + .article-third-card__content .news-link,
.news-title:hover + .news-link {
    border: 0;

    &.red-underline {
        position: relative;
        border: 0;

        &:hover {
            border: 0;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: -8px;
            width: 100%;
            height: 5px;
            margin: 5px 0 0;
            transition: $animate;
            transition-duration: 0.75s;
            opacity: 1;
            background-color: var(--mc-red);
        }

        .link-arrow {
            transform: translate(2px, -2px);
        }
    }
}
