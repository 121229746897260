.leadership {
    &__description {
        width: 100%;
        max-width: 687px;
        margin-bottom: 4.8rem;

        @include mediaSM {
            margin-bottom: 3.8rem;
        }

        @include mediaXS {
            margin-bottom: 2.6rem;
        }
    }

    &__team {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 5.3rem;
        row-gap: 6.2rem;

        @include mediaMD {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 3.3rem;
        }

        @include mediaXS {
            column-gap: 1.9rem;
        }

        @media (max-width: 374px) {
            grid-template-columns: 1fr;
        }
    }

    &__member {
        position: relative;
        width: 100%;

        .member-headshot {
            cursor: pointer;
            position: relative;
            width: 100%;
            height: 20vw;
            max-height: 305px;
            overflow: hidden;
            border-radius: 4px;
            margin-bottom: 1.5rem;

            @include mediaMD {
                height: 30vw;
                max-height: unset;
            }

            @include mediaXS {
                height: 40vw;
            }

            @media (max-width: 374px) {
                height: 250px;
            }

            &::after {
                content: 'FPO';
                position: absolute;
                bottom: 5%;
                right: 7%;
                font-size: 2.1rem;
                line-height: 2.6rem;
                letter-spacing: 3.2px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center 45%;
            }
        }

        .member-name {
            font-size: 2.3rem;
            line-height: 2.8rem;
            letter-spacing: 1.2px;
            font-weight: 500;
            margin-bottom: 1.5rem;

            @include mediaSM {
                font-size: 2rem;
                margin-bottom: 0.6rem;
            }

            @include mediaXS {
                font-size: 1.4rem;
                line-height: 1.7rem;
                margin-bottom: 0.1rem;
            }
        }

        .member-position {
            font-size: 1.8rem;
            line-height: 2.6rem;
            letter-spacing: 1px;
            opacity: 0.8;
            margin-bottom: 1.5rem;
            font-weight: 400;

            @include mediaSM {
                font-size: 1.6rem;
                margin-bottom: 1.2rem;
            }

            @include mediaXS {
                font-size: 1.1rem;
                line-height: 2.6rem;
                margin-bottom: 5px;
            }
        }

        .member-links {
            width: 100%;
            max-width: 242px;
            font-size: 1.2rem;
            line-height: 1.4rem;
            letter-spacing: 0.42px;
            opacity: 0.8;
            display: flex;
            justify-content: space-between;

            @include mediaSM {
                column-gap: 5px;
            }

            @include mediaXS {
                flex-direction: column;
            }

            &__learn {
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;

                @include mediaXS {
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    margin-left: 2px;
                    margin-bottom: 7px;
                    margin-right: 0;
                }

                &-plus {
                    margin-left: 3px;

                    @include mediaXS {
                        margin-left: 0;
                        margin-right: 6px;
                    }
                }
            }

            &__separator {
                @include mediaXS {
                    display: none;
                }
            }

            &__download {
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;

                .link-underline {
                    margin-right: 6px;

                    @include mediaXS {
                        margin-left: 5px;
                        margin-right: 0;
                    }
                }

                @include mediaXS {
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                }
            }
        }
    }
}

.bio {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    @include mediaXS {
        background: var(--mc-black);
    }

    .blur-overlay {
        position: relative;
        $blur: 34px;

        width: 100%;
        height: 100%;
        background: rgba(30, 30, 30, 0.74);
        filter: blur($blur);
        -webkit-filter: blur($blur);
        -moz-filter: blur($blur);
        -o-filter: blur($blur);
        -ms-filter: blur($blur);
        backdrop-filter: blur($blur);

        @include mediaXS {
            display: none;
        }
    }

    .bio-popup {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2% 5%;

        @include mediaXS {
            padding: 0;
        }

        &__container {
            position: relative;
            width: 100%;
            max-width: 1145px;
            height: 100%;
            max-height: 754px;

            @include mediaSM {
                max-height: 453px;
            }

            @include mediaXS {
                max-height: unset;
                max-width: unset;
            }
        }

        &__wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            background: #000000;
            display: flex;
            overflow: hidden;
            border-radius: 12px;

            @include mediaXS {
                flex-direction: column;
                border-radius: 0;
            }
        }

        &__headshot {
            position: relative;
            overflow: hidden;
            width: 100%;
            max-width: 580px;
            height: 100%;

            @include mediaSM {
                max-width: 348px;
            }

            @include mediaXS {
                max-width: unset;
                height: 55vw;
            }

            @include mediaXXS {
                height: 250px;
                flex-shrink: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;

                @include mediaXS {
                    object-position: center 38%;
                }
            }

            &::after {
                content: 'FPO';
                position: absolute;
                bottom: 5%;
                left: 5%;
                font-family: Sohne;
                font-size: 30px;
                line-height: 34px;
                letter-spacing: 0.44px;
                color: #919191;

                @include mediaSM {
                    display: none;
                }
            }
        }

        &__exit {
            cursor: pointer;
            position: absolute;
            top: 5%;
            left: 6%;

            width: 26px;
            height: 26px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 2px;
                background: white;
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 2px;
                background: white;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        &__content {
            width: 100%;

            @include mediaXS {
                height: calc(100% - 55vw);
            }

            @include mediaXXS {
                height: calc(100% - 250px);
            }

            &-wrapper {
                padding-left: 5.9rem;
                margin-right: 1rem;
                padding-top: 7rem;
                height: 100%;
                overflow-y: auto;

                @include mediaSM {
                    padding-top: 4rem;
                    padding-left: 3.3rem;
                }

                @include mediaXS {
                    padding-top: 3.1rem;
                    padding-left: 2.5rem;
                    margin-right: 0;
                    padding-bottom: 8rem;
                }

                /* width */
                &::-webkit-scrollbar {
                    width: 4px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 0 5px #2D2D2D;
                    margin-top: 44px;
                    margin-bottom: 24px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #A2A2A2;
                }
            }

            .member-name {
                font-size: 3.8rem;
                line-height: 4.7rem;
                letter-spacing: 3.44px;
                margin-bottom: 1.5rem;

                @include mediaSM {
                    font-size: 2rem;
                    margin-bottom: 0;
                }
            }

            .member-position {
                font-size: 2.2rem;
                line-height: 3rem;
                letter-spacing: 0.2px;
                font-weight: 400;
                opacity: 0.9;
                margin-bottom: 4rem;

                @include mediaSM {
                    font-size: 1.7rem;
                    line-height: 2.4rem;
                    margin-bottom: 2.8rem;
                }
            }

            .bio-summary {
                width: 100%;
                max-width: 471px;
                margin-bottom: 5rem;
                font-size: 1.7rem;
                line-height: 2.4rem;
                letter-spacing: 0.2px;
                padding-right: 2rem;

                @include mediaSM {
                    font-size: 1.5rem;
                    max-width: unset;
                    padding-right: 1.8rem;
                    margin-bottom: 3.5rem;
                }

                @include mediaXS {
                    overflow-y: auto;

                }

                p {
                    margin-bottom: 3rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .bio-buttons {
                display: flex;
                font-size: 1.3rem;
                line-height: 1.5rem;
                letter-spacing: 0.42px;
                color: #FFFEF2;
                margin-bottom: 6rem;

                &__copy, &__download {
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    svg {
                        width: 1.4rem;
                        height: 1.4rem;
                        margin-right: 5px;
                    }
                }

                &__copy {
                    position: relative;
                    margin-right: 50px;

                    @include mediaSM {
                        margin-right: 30px;
                    }

                    .copied {
                        position: absolute;
                        margin-bottom: 3.8rem;
                        left: 50%;
                        transform: translateX(-50%);
                        width: auto;
                        padding: 0.2rem;
                        font-size: 1.3rem;
                        line-height: 1.5rem;
                        letter-spacing: 0.42px;
                        z-index: 2;
                        border: 1px solid black;
                        background: white;
                        color: black;
                    }
                }
            }
        }
    }
}
