$animate: all 0.2s ease-in-out;

.container {
    position: relative;
    max-width: var(--desktop-container-width);
    width: 100%;
    color: $mc-cream;
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
    padding-right: 100px;

    @include mediaSM {
        padding-left: 40px;
        padding-right: 40px;
        max-width: 100%;
    }

    @include mediaXS {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.container-fluid-xs {
    @include mediaXS {
        width: 100%;
        max-width: unset;
        padding-right: 0;
        padding-left: 0;
    }
}

img {
    position: relative;
    width: 100%;
    height: auto;
}

.muted {
    opacity: 0.8;
}

.lowercase {
    text-transform: lowercase;
}

.muted-link {
    color: rgba(255,255,255, 0.8);
}

hr {
    width: 48px;
    height: 7px;
    border: 0;
    background-color: var(--mc-red);
}

ul {
    list-style: none;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: var(--mc-cream);
    border-bottom: 2px solid transparent;

    &:hover {
        border-color: var(--mc-cream);
    }
}

h1 {
    font-family: 'Sohne Schmal', sans-serif;
    font-size: 220px;
    line-height: 190px;
    text-transform: uppercase;
}

h2 {
    font-family: 'Sohne Schmal', sans-serif;
    font-size: 150px;
    line-height: 133px;
    text-transform: uppercase;
}

.section-title {
    font-family: 'Sohne', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 3.44px;
    text-transform: uppercase;
}

.quote {
    position: relative;
    font-family: 'Sohne', sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.44px;

    &.red-quotations {
        .startquote {
            display: inline-block;
            position: absolute;
            color: var(--mc-red);
            font-size: 67px;
            height: 67px;
            line-height: 67px;
            margin-left: -35px;
            margin-top: -8px;

            &:after {
                content: '\201C';
            }

            @include mediaSM {
                margin-top: -8px;
            }

            @include mediaXS {
                font-size: 49px;
                margin-top: -13px;
                margin-left: -25px;
            }
        }

        .endquote {
            display: inline-block;
            position: absolute;
            color: var(--mc-red);
            font-size: 67px;
            height: 67px;
            line-height: 67px;
            margin-left: 10px;
            margin-top: 10px;

            &:after {
                content: '\201D';
            }

            @include mediaSM {
                margin-top: 5px;
            }

            @include mediaXS {
                font-size: 49px;
                margin-top: -2px;
            }
        }
    }
}

.news-title-28 {
    font-family: 'Sohne', sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.2px;
}

.news-title-24 {
    font-family: 'Sohne', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.2px;
}

.news-link {
    font-family: 'Sohne', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: auto;
    letter-spacing: 0.42px;
    width: max-content;

    position: relative;
    transition: $animate;
    transition-duration: 0.75s;

    .link-arrow {
        display: inline;
        width: 8px;
        height: 8px !important;
        transition: $animate;
        transition-duration: 0.75s;
    }
}

.red-underline {
    position: relative;
    border: 0;

    &:hover {
        border: 0;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: -8px;
        width: 0;
        height: 5px;
        margin: 5px 0 0;
        transition: $animate;
        transition-duration: 0.75s;
        opacity: 0;
        background-color: var(--mc-red);
      }

      &:hover {
        border: 0;

        &:before {
            width: 100%;
            opacity: 1;
        }

        .link-arrow {
            transform: translate(2px, -2px);
        }
    }
}

.btn {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 11px;
    padding: 15px 25px;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 1.44px;
    color: var(--mc-cream);

    background: var(--mc-darker-gray);

    &.lowercase {
        text-transform: lowercase;
    }

    &:hover {
        background: var(--mc-dark-gray);
        border: none;
    }

    &:active {
        background: var(--mc-darker-gray);
    }

    &.btn-primary {
        background: var(--mc-red);

        &:hover {
            background: var(--mc-light-red);
        }

        &:active {
            background: var(--mc-red);
        }
    }

    &-link {
        width: 100%;
        max-width: max-content;
    }
}

.link-underline:hover {
    text-decoration: underline;
}

.desktop-only {
    @include mediaSM {
        display: none !important;
    }
}

.tablet-only {
    @include media($mediaMD) {
        display: none !important;
    }

    @include mediaXS {
        display: none !important;
    }
}

.mobile-only {
    @include media($mediaSM) {
        display: none !important;
    }
}

.desktop-and-tablet-only {
    @include mediaXS {
        display: none !important;
    }
}

