.mc-about {
    section {
        padding-top: 8.4rem;
        margin-bottom: 6rem;

        @include mediaSM {
            padding-top: 6rem;
            margin-bottom: 5rem;
        }

        @include mediaXS {
            margin-bottom: 3rem;
        }
    }

    hr {
        width: 4.8rem;
        height: 0.7rem;
        background-color: var(--mc-red);
        margin-bottom: 2rem;

        @include mediaXS {
            width: 3.8rem;
            height: 0.5rem;
            margin-bottom: 1.2rem;
        }
    }

    .title {
        font-size: 1.8rem;
        line-height: 2rem;
        letter-spacing: 3.44px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 4rem;

        @include mediaXS {
            font-size: 1.2rem;
            line-height: 1.4rem;
            letter-spacing: 1.44px;
            margin-bottom: 2.3rem;
        }
    }

    .about-nav-btn {
        cursor: pointer;
        border: none;
        background: none;
        color: var(--mc-cream);
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 2.44px;
        font-weight: 600;

        &:hover {
            color: var(--mc-red);
        }

        &.active {
            color: var(--mc-red);
        }
    }

    &__hero {
        overflow: hidden;
        position: relative;
        margin-bottom: 0.9rem;

        &-wrapper {
            position: relative;
            height: 445px;
            display: flex;

            @include mediaSM {
                height: 312px;
            }

            @include mediaXS {
                height: 360px;
            }
        }

        &-title {
            font-family: 'Sohne Schmal', sans-serif;
            font-size: 15rem;
            line-height: 13.3rem;
            text-transform: uppercase;
            position: absolute;
            z-index: 0;
            width: 100%;
            max-width: 469px;
            left: 16%;
            align-self: center;

            @include mediaSM {
                left: 0;
                align-self: flex-end;
            }

            @include mediaXS {
                font-size: 7.2rem;
                line-height: 6.4rem;
                max-width: 215px;
                align-self: flex-start;
                padding-left: 5%;
            }
        }

        &-image {
            position: relative;
            z-index: -1;
            margin-left: auto;
            width: 57%;
            height: 100%;
            align-self: center;

            @include mediaSM {
                width: 59%;
                height: 33vw;
                align-self: flex-start;
            }

            @include mediaXS {
                align-self: flex-end;
                width: 100%;
                max-width: 350px;
                height: 222px;
                margin-left: 20%;
                margin-bottom: 3rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;

                @include mediaXS {
                    object-fit: cover;
                    object-position: -50px center;
                }
            }
        }
    }

    &__content {
        margin-bottom: 4rem;
    }

    &__grid {
        display: grid;
        grid-template-columns: auto 1fr;

        @include mediaXS {
            display: block;
        }
    }

    &__nav {
        margin-top: 9rem;
        margin-right: 9.7rem;
        margin-bottom: 16rem;

        @include mediaSM {
            margin-right: 6.7rem;
        }

        @include mediaXS {
            display: none;
        }

        ul {
            position: sticky;
            top: 35%;
        }

        li {
            display: flex;
            margin-bottom: 3.1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__mobile-nav {
        position: sticky;
        top: 0;
        z-index: 1;
        display: none;
        width: 100%;
        height: 100%;
        overflow-x: auto;
        background: var(--mc-black);
        font-size: 1.2rem;
        line-height: 1.4rem;
        letter-spacing: 1.44px;
        padding-left: 2.5rem;
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include mediaXS {
            display: block;
        }

        ul {
            position: relative;
            width: max-content;
            display: flex;
            align-items: center;
            background: var(--mc-black);
        }

        li {
            width: max-content;
            margin-right: 4rem;
            &:last-child {
                padding-right: 1.5rem;
            }
        }

        .about-nav-btn {
            display: block;
            font-size: 1.2rem;
            line-height: 1.4rem;
            letter-spacing: 1.44px;
            width: 100%;

            &:hover {
                color: var(--mc-red);
            }

            &.active {
                color: var(--mc-red);
            }
        }
    }
}
