.fade-in-section {
    position: inherit;
    opacity: 0;
    transform: translateY(2vh);
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    will-change: opacity, visibility;
  }

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
}