:root {
    --mc-black: #000000;
    --mc-red: #e32652;
    --mc-cream: #ffffff;
    --mc-light-red: #ff748c;
    --mc-darker-gray: #353535;
    --mc-dark-gray: #4D4D4D;
}

$mc-gray: #161616;
$mc-black: #000000;
$mc-cream: #ffffff;