.article-press-release {
    position: relative;
    width: 100%;
    max-width: 590px;

    &__wrapper {
        position: relative;
        display: flex;
    }

    &__image {
        cursor: pointer;
        position: relative;
        width: 177px;
        height: 177px;
        margin-right: 4rem;
        flex-shrink: 0;

        @include mediaSM {
            width: 150px;
            height: 150px;
            margin-right: 3rem;
        }

        @include mediaXS {
            width: 100px;
            height: 100px;
            margin-right: 1.8rem;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__eyebrow {
        opacity: 0.9;
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.42px;
        margin-bottom: 1.4rem;

        @include mediaXS {
            font-size: 1.2rem;
            line-height: 1.4rem;
        }
    }

    &__headline {
        font-size: 2.3rem;
        line-height: 2.8rem;
        letter-spacing: 0.2px;
        margin-bottom: 1.4rem;
        width: 100%;
        max-width: 373px;

        @include mediaSM {
            font-size: 2rem;
            line-height: 2.8rem;
            max-width: 471px;
        }

        @include mediaXS {
            font-size: 1.6rem;
            line-height: 2.1rem;
            letter-spacing: 0.42px;
            max-width: unset;
        }
    }

    &__link {
        display: block;

        @include mediaXS {
            font-size: 1.3rem;
            line-height: 1.5rem;
        }

        &.red-underline {
            position: relative;
            border: 0;

            &:hover {
                border: 0;
            }

            &:before {
                content: "";
                position: absolute;
                bottom: -4px;
                width: 0;
                height: 5px;
                margin: 5px 0 0;
                transition: $animate;
                transition-duration: 0.75s;
                opacity: 0;
                background-color: var(--mc-red);
              }

              &:hover {
                border: 0;

                &:before {
                    width: 100%;
                    opacity: 1;
                }

                .link-arrow {
                    transform: translate(2px, -2px);
                }
            }
        }
    }

    .article-press-release__image:hover ~ .article-press-release__content {
        .news-link {
            border: 0;

            &.red-underline {
                position: relative;
                border: 0;

                &:hover {
                    border: 0;
                }

                &:before {
                    content: "";
                    position: absolute;
                    bottom: -4px;
                    width: 100%;
                    height: 5px;
                    margin: 5px 0 0;
                    transition: $animate;
                    transition-duration: 0.75s;
                    opacity: 1;
                    background-color: var(--mc-red);
                }

                .link-arrow {
                    transform: translate(2px, -2px);
                }
            }
        }
    }
}
