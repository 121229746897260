.our-story {
    position: relative;

    &__story {
        width: 100%;
        max-width: 811px;
        font-size: 2rem;
        line-height: 2.8rem;
        letter-spacing: 0.2px;
        margin-bottom: 5rem;

        @include mediaSM {
            max-width: unset;
            margin-bottom: 3rem;
        }

        @include mediaXS {
            font-size: 1.6rem;
            line-height: 2.3rem;
            letter-spacing: 0.4px;
        }

        p {
            margin-bottom: 3rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__stats {
        display: flex;
        flex-wrap: wrap;
        column-gap: 7.2rem;
        row-gap: 3.8rem;

        @include mediaSM {
            row-gap: 3.1rem;
        }

        @include mediaXS {
            column-gap: 2rem;
            row-gap: 2.5rem;
        }
    }

    &__stat {
        position: relative;
        width: 100%;
        max-width: 200px;
        text-align: center;

        @include mediaXS {
            max-width: 152px;
        }

        .stat-big-text-container {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 100%;
            height: 133px;
            overflow: hidden;

            @include mediaXS {
                align-items: unset;
                height: 100px;
            }
        }

        .stat-big-text {
            display: inline-block;
            font-family: "Sohne Schmal", sans-serif;
            font-size: 6rem;
            line-height: 6.1rem;
            text-transform: uppercase;
            overflow: hidden;

            @include mediaXS {
                font-size: 4.9rem;
                line-height: 4.6rem;
            }

            &.stat-number {
                font-size: 15rem;
                line-height: 13.3rem;

                @include mediaXS {
                    font-size: 8rem;
                    line-height: 11rem;
                }
            }
        }

        .stat-caption {
            font-size: 1.4rem;
            line-height: 2.4rem;
            letter-spacing: 0.2px;
            text-align: center;

            @include mediaXS {
                font-size: 1.3rem;
                line-height: 1.6rem;
                letter-spacing: 0.4px;
            }
        }
    }
}
