.mobile-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    background: var(--mc-black);
    z-index: 99;
    opacity: 0;
    transition: opacity 0.3s ease-out, transform 0.6s ease-out;

    &.active {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        opacity: 1;
    }

    &__wrapper {
        position: relative;

        .nav-menu-exit {
            position: absolute;
            top: 22px;
            left: 21px;
            cursor: pointer;
        }
    }
}
