/* Sohne - Regular */
@font-face {
    font-family: 'Sohne';
    src: url('../../assets/fonts/sohne-buch.woff2') format('woff2'),
        url('../../assets/fonts/sohne-buch.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: auto;
}

/* Sohne - Bold */
@font-face {
    font-family: 'Sohne';
    src: url('../../assets/fonts/sohne-halbfett.woff2') format('woff2'),
        url('../../assets/fonts/sohne-halbfett.woff') format('woff');
    font-style: normal;
    font-weight: 500;
    font-display: block;
}

/* Sohne - Schmal - Semibold */
@font-face {
    font-family: 'Sohne Schmal';
    src: url('../../assets/fonts/sohne-schmal-dreiviertelfett.woff2') format('woff2'),
        url('../../assets/fonts/sohne-schmal-dreiviertelfett.woff') format('woff');
    font-style: normal;
    font-weight: 500;
    font-display: block;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

:root {
    --mc-theme-border-contrast: rgba(255, 255, 255, 0.5);
    --mc-theme-border-contrast-hover: #fff;
    --mc-theme-text: #fff;

    --mc-scale-0: 0rem;
    --mc-scale-1: 0.4rem;
    --mc-scale-2: 0.8rem;
    --mc-scale-3: 1.2rem;
    --mc-scale-4: 1.6rem;
    --mc-scale-5: 1.8rem;
    --mc-scale-6: 2rem;
    --mc-scale-7: 2.2rem;
    --mc-scale-8: 2.4rem;
    --mc-scale-9: 3.8rem;
    --mc-scale-10: 5.2rem;
    --mc-scale-11: 6.6rem;
    --mc-scale-12: 8rem;
    --mc-scale-3-5: 1.4rem;

    @media (min-width: 960px) {
        --mc-scale-0: 0rem;
        --mc-scale-1: 0.4rem;
        --mc-scale-2: 0.8rem;
        --mc-scale-3: 1.2rem;
        --mc-scale-4: 1.6rem;
        --mc-scale-5: 2rem;
        --mc-scale-6: 2.4rem;
        --mc-scale-7: 2.8rem;
        --mc-scale-8: 3.2rem;
        --mc-scale-9: 4.8rem;
        --mc-scale-10: 6.4rem;
        --mc-scale-11: 8rem;
        --mc-scale-12: 9.6rem;
    }

    @media (min-width: 768px) {
        --mc-scale-0: 0rem;
        --mc-scale-1: 0.4rem;
        --mc-scale-2: 0.8rem;
        --mc-scale-3: 1.2rem;
        --mc-scale-4: 1.6rem;
        --mc-scale-5: 1.9rem;
        --mc-scale-6: 2.2rem;
        --mc-scale-7: 2.5rem;
        --mc-scale-8: 2.8rem;
        --mc-scale-9: 4.3rem;
        --mc-scale-10: 5.8rem;
        --mc-scale-11: 7.3rem;
        --mc-scale-12: 8.8rem;
    }
}

html {
    font-size: 10px !important;
    overflow-x: hidden;
}

html, body, #root {
    position: relative;
    width: 100%;
}

body {
    background: $mc-black;
    color: $mc-cream;
    font-size: 20px;
    font-family: 'Sohne', 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
    -webkit-font-smoothing: antialiased;
}

main {
    min-height: 100vh;
}
