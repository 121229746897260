.timeline {
    position: relative;

    .section-title {
        margin-bottom: 48px;

        @include mediaSM {
            margin-bottom: 40px;
        }

        @include mediaXS {
            margin-left: 0;
        }
    }

    .flex-wrapper {
        display: flex;
        column-gap: 22px;
    }

    .year {
        cursor: pointer;
        user-select: none;

        &.active {
            color: var(--mc-red);
        }
    }

    &__bar {
        ul {
            max-width: 130px;

            @include mediaSM {
                max-width: 85px;
            }

            li {
                position: relative;
                margin-bottom: 36px;
                display: flex;
                padding: 13px 48px 13px 15px;
                width: 100%;

                @include mediaSM {
                    margin-bottom: 20px;
                    padding: 16px 16px 10px 17px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 36%;
                    width: 2px;
                    transform: translate(-36%, 0);
                    height: 36px;
                    background: #979797;

                    @include mediaSM {
                        left: 49%;
                        transform: translate(-49%, 0);
                        height: 20px;
                    }
                }

                &:last-child {
                    margin-bottom: 0;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        span {
            user-select: none;
            font-family: 'Sohne';
            font-size: 24px;
            line-height: 31px;
            font-weight: 500;
            text-align: center;
            letter-spacing: 2.2px;

            @include mediaSM {
                font-size: 20px;
            }
        }
    }

    &__details {
        margin-top: 10px;

        @include mediaSM {
            margin-top: 18px;
        }

        @include mediaXS {
            margin-top: 5px;
            margin-bottom: 24px;
            max-height: 0;
            overflow: hidden;
            transform: scaleY(0);
            transform-origin: top;
            transition: transform 0.4s ease;
        }

        &.active {
            transform: scaleY(1);
            max-height: 100%;
        }

        &-description {
            margin-bottom: 30px;
            max-width: 639px;

            &:only-child {
                margin-bottom: 0;
            }

            @include mediaSM {
                margin-bottom: 36px;
                max-width: 419px;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.4px;
            }

            @include mediaXS {
                margin-bottom: 18px;
                max-width: 100%;
                padding-right: 9%;
            }

            p {
                margin-bottom: 18px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-image {
            max-width: 700px;

            @include mediaSM {
                max-width: 418px;
            }

            @include mediaXS {
                max-width: 100%;
            }

            .image-caption {
                display: block;
                font-family: Helvetica, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 10px;
                letter-spacing: 0.02em;
                color: #D9D9D9;
            }
        }
    }

    &__mobile {
        position: relative;
        overflow: hidden;
        transition: height 5s;
        min-height: 50px;

        &::after {
            content: '';
            position: absolute;
            top: 32px;
            left: 18px;
            width: 2px;
            height: 100%;
            background: #979797;
        }

        &:last-child {
            min-height: 33px;
        }

        .year {
            align-self: flex-start;
            position: relative;
            user-select: none;
            font-family: 'Sohne';
            font-size: 15px;
            line-height: 30px;
            font-weight: 500;
            text-align: center;
            letter-spacing: 1.4px;
        }
    }
}
