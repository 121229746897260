.press {
    margin-top: 10px;
    padding-bottom: 50px;

    &__hero {
        max-width: 588px;
        margin-top: 60px;
        margin-bottom: 54px;

        @include mediaXS {
            font-size: 72px;
            line-height: 64px;
            max-width: unset;
            padding-right: 14%;
            margin-top: 40px;
        }
    }

    &__bar {
        position: sticky;
        top: -1px;
        z-index: 3;
        margin-bottom: 40px;
        height: 80px;
        background-color: var(--mc-black);

        @include mediaSM {
            height: 70px;
        }

        @include mediaXS {
            height: 60px;
            margin-bottom: 20px;
        }


        &-wrapper {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: var(--mc-black);
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.5);
            transition: transform 300ms;
            transition-timing-function: ease-out;

            .flex-wrapper {
                height: 100%;
                display: flex;
                align-items: center;
                position: relative;
                // z-index: 10;
                justify-content: space-between;
            }
        }

        &.is-pinned &-wrapper {
            border-bottom: 1px solid #565656;
            &.is-navbar {
                transition-timing-function: ease-in;
                transform: translateY(84px);

                @include mediaSM {
                    transform: translateY(60px);
                }
            }
        }
    }

    &__filters {
        display: flex;
        column-gap: 30px;
        font-size: 14px;
        line-height: 16px;
        height: 100%;

        &__mobile {
            max-height: calc(100vh - 170px);
            overflow-y: auto;

            .filter {
                cursor: pointer;
                font-size: 14px;
                line-height: 50px;
                letter-spacing: 0.42px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                span {
                    display: flex;
                    padding: 0 25px;
                    align-items: center;
                    height: 50px;
                }

                &__dropdown {
                    li {
                        position: relative;
                        display: flex;
                        align-items: center;
                        height: 50px;

                        a {
                            display: flex;
                            padding-left: 12%;
                            width: 100%;
                            height: 100%;
                            align-items: center;
                            border: 0;

                            &:hover {
                                border: 0;
                            }
                        }

                        &:hover {
                            background: #393939;
                        }
                    }
                }
            }
        }

        .filter {
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;
            z-index: 5;

            span {
                cursor: pointer;
                user-select: none;
            }

            &__dropdown {
                position: absolute;
                top: 100%;
                left: 0;
                width: max-content;
                z-index: -1;

                &-container {
                    position: relative;
                    z-index: 1;
                    padding: 10px 0 15px;
                    background: #1F1F1F;
                    box-shadow: 0px 0px 18px #000000;
                }

                li {
                    cursor: pointer;
                    // padding: 15px 18px;

                    &:hover {
                        background: #383838;
                    }
                }

                a {
                    display: block;
                    padding: 15px 18px;
                    border: 0;
                    &:hover {
                        border: 0;
                    }
                }
            }
        }
    }

    &__search {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;

        form {
            width: 100%;
            max-width: 373px;
        }

        img {
            width: 18px;
            cursor: pointer;
        }

        &__mobile {
            height: 100%;
            display: flex;
            align-items: center;
            width: 100%;

            form {
                max-width: unset;
                width: 100%;
            }
        }
    }

    &__articles {
        min-height: 800px;
        margin-bottom: 101px;

        &:only-child {
            margin-bottom: 0;
        }

        .results-indicator {
            display: inline-block;
            font-size: 14px;
            line-height: 16px;
            color: #ACACAC;
            margin-bottom: 30px;

            @include mediaSM {
                margin-bottom: 18px;
            }

            @include mediaXS {
                margin-bottom: 16px;
            }
        }

        .section-title {
            margin-bottom: 50px;

            hr {
                margin-bottom: 15px;
            }
        }

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 60px;

            @include mediaSM {
                grid-template-columns: repeat(2, 1fr);
            }

            @include mediaXS {
                grid-template-columns: 1fr;
            }
        }

        .no-results {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;

            span {
                display: block;
            }
        }

        &-highlights {
            margin-bottom: 5rem;

            @include mediaXS {
                margin-bottom: 3rem;
            }

            hr {
                width: 48px;
                margin-bottom: 2rem;

                @include mediaXS {
                    height: 4px;
                    width: 38px;
                }
            }

            &-header {
                font-size: 1.8rem;
                line-height: 2rem;
                letter-spacing: 3.44px;
                text-transform: uppercase;

                @include mediaXS {
                    font-size: 1.4rem;
                    letter-spacing: 2.44px;
                }
            }
        }
    }

    .mobile-menu__wrapper {
        padding-top: 80px;

        .press__search__mobile {
            padding: 0 25px;
            margin-bottom: 20px;
        }
    }

    .page-bottom-boundary {
        position: relative;
        width: 100%;
        height: 1px;

        img {
            position: absolute;
            width: auto !important;
            height: auto !important;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
        }

        &.show img {
            opacity: 1;
        }
    }


    &__releases {
        position: relative;

        &-header {
            margin-bottom: 5rem;

            @include mediaXS {
                margin-bottom: 3rem;
            }

            hr {
                width: 48px;
                margin-bottom: 2rem;

                @include mediaXS {
                    height: 4px;
                    width: 38px;
                }
            }

            &-text {
                font-size: 1.8rem;
                line-height: 2rem;
                letter-spacing: 3.44px;
                text-transform: uppercase;

                @include mediaXS {
                    font-size: 1.4rem;
                    letter-spacing: 2.44px;
                }
            }
        }

        &-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 2rem;
            row-gap: 7.6rem;

            @include mediaSM {
                grid-template-columns: 1fr;
                row-gap: 5rem;
            }

            @include mediaXS {
                row-gap: 4rem;
            }
        }

        .article-press-release {
            max-width: unset;
        }
    }

    .press__bar:not(.is-pinned) {
        .filter__dropdown {
            top: 80%;
        }
    }
}
