.article-hero {
    position: relative;
    width: 800px;
    height: 500px;
    z-index: 1;

    @include mediaSM {
        width: 630px;
        height: 500px;
    }

    @include mediaXS {
        width: 100%;
        height: 100%;
        padding-bottom: 28px;
    }

    > img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        @include mediaXS {
            height: 234px;
            margin-bottom: 18px;
            display: block;
        }
    }

    .gradient {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 302px;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #181818 100%);

        @include mediaXS {
            display: none;
        }
    }

    .article-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 47px;
        padding-right: 48px;

        @include mediaSM {
            padding-left: 37px;
            padding-right: 60px;
        }

        @include mediaXS {
            position: relative;
            padding: 0;
            padding-left: 24px;
            padding-right: 24px;
            max-width: 100%;
        }

        h4 {
            cursor: pointer;
            margin-bottom: 6px;

            @include mediaSM {
                margin-bottom: 6px;
                font-size: 20px;
                line-height: 34px;
                letter-spacing: 0.2px;
            }

            @include mediaXS {
                margin-bottom: 0;
                max-width: 94%;
                font-size: 20px;
                line-height: 23px;
                letter-spacing: 0.42px;
            }
        }

        a {
            @include mediaXS {
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0.42px;
            }

            img {
                margin-left: 5px;
                width: 7.5px;
                height: 8px;

                @include mediaSM {
                    margin-left: 3px;
                }
            }
        }
    }

    .gradient:hover ~ .article-content .news-link,
    img:hover ~ .article-content .news-link,
    .article-hero-title:hover ~ .news-link {
        border: 0;

        &.red-underline {
            position: relative;
            border: 0;

            &:hover {
                border: 0;
            }

            &:before {
                content: "";
                position: absolute;
                bottom: -8px;
                width: 100%;
                height: 5px;
                margin: 5px 0 0;
                transition: $animate;
                transition-duration: 0.75s;
                opacity: 1;
                background-color: var(--mc-red);
            }

            .link-arrow {
                transform: translate(2px, -2px);
            }
        }
    }
}
