.mc-page__footer {
    font-size: 10px;
    min-height: 200px;

    a {
        border-bottom: unset;

        &:hover {
            border-color: unset;
        }
    }

    svg {
        vertical-align: middle;
    }

    .d-none {
        display: none !important;
    }

    .d-md-block {
        @media (min-width: 768px) {
            display: block !important;
        }
    }

    .d-md-none {
        @media (min-width: 768px) {
            display: none !important;
        }
    }

    .d-inline-flex {
        display: inline-flex !important;
    }

    .d-flex {
        display: flex !important;
    }

    .justify-content-center {
        justify-content: center !important;
    }

    .align-items-center {
        align-items: center !important;
    }

    .mc-text--center {
        text-align: center !important;
    }

    .mc-text--link,
    .mc-text--link:hover,
    .mc-text--bare-link:hover,
    .mc-text--bare-link-parent:hover .mc-text--bare-link {
        text-decoration: underline;
        cursor: pointer;
    }

    .mc-separator {
        width: 100%;
        height: 1px;
        border: none;
        background: rgba(255, 255, 255, 0.3);
    }

    .mc-icon {
        width: 3.2em;
        height: 3.2em;
    }

    .mc-icon--4 {
        font-size: var(--mc-scale-4);
    }

    .mc-icon--3 {
        font-size: var(--mc-scale-3);
    }

    .footer-container {
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        margin-right: auto;
        margin-left: auto;

        @media only screen and (min-width: 576px) {
            max-width: 100%;
            padding: 0 32px;
        }

        @media only screen and (min-width: 768px) {
            max-width: 100%;
            padding: 0 32px;
        }

        @media only screen and (min-width: 960px) {
            max-width: 100%;
            padding: 0 64px;
        }

        @media only screen and (min-width: 1200px) {
            max-width: 1328px;
            padding: 0 64px;
        }

    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin: -8px;

        @media only screen and (min-width: 1200px) {
            margin: -16px;
        }
    }

    .col, [class^="col-"], [class*=" col-"] {
        padding: 8px;
    }

    .col-auto, .col-auto-max {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        padding: 8px;

        @media only screen and (min-width: 1200px) {
            padding: 16px;
        }
    }

    .col-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .c-button:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    .c-button {
        padding: var(--mc-scale-3) var(--mc-scale-7);
        font-size: var(--mc-scale-3);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 500;
        line-height: 2;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        border-radius: var(--mc-scale-3);
        transition: background 250ms ease, box-shadow 250ms ease, opacity 250ms ease;
        vertical-align: middle;
        border: 0;
        font-family: "Sohne", "Helvetica", "Arial", sans-serif;
    }

    .c-button--tertiary {
        background: none;
        box-shadow: inset 0 0 0 1px var(--mc-theme-border-contrast);
        color: var(--mc-theme-text);
    }

    .c-button--tertiary:not(:disabled):not(.disabled):active {
        background: none;
        box-shadow: inset 0 0 0 1px var(--mc-theme-border-contrast);
    }

    .c-button--tertiary:not(:disabled):not(.disabled):hover {
        background: none;
        box-shadow: inset 0 0 0 1px var(--mc-theme-border-contrast-hover);
    }

    .col-2 svg {
        font-size: var(--mc-scale-4) !important;
    }

    // -------------------------- Margin -------------------------------

    .mc-mx-4 {
        margin-left: var(--mc-scale-4);
        margin-right: var(--mc-scale-4);
    }

    .mc-my-10 {
        margin-top: var(--mc-scale-10);
        margin-bottom: var(--mc-scale-10);
    }

    .mc-my-9 {
        margin-top: var(--mc-scale-9);
        margin-bottom: var(--mc-scale-9);
    }

    .mc-my-4 {
        margin-top: var(--mc-scale-4);
        margin-bottom: var(--mc-scale-4);
    }

    .mc-my-3 {
        margin-top: var(--mc-scale-3);
        margin-bottom: var(--mc-scale-3);
    }

    .mc-mb-9 {
        margin-bottom: var(--mc-scale-9);
    }

    .mc-mb-6 {
        margin-bottom: var(--mc-scale-6);
    }

    .mc-mb-5 {
        margin-bottom: var(--mc-scale-5);
    }

    .mc-mb-4 {
        margin-bottom: var(--mc-scale-4);
    }

    .mc-mt-4 {
        margin-top: var(--mc-scale-4);
    }

    .mc-mt-2 {
        margin-top: var(--mc-scale-2);
    }

    .mc-mr-4 {
        margin-right: var(--mc-scale-4);
    }

    .mc-mr-3 {
        margin-right: var(--mc-scale-3);
    }

    .mc-mr-2 {
        margin-right: var(--mc-scale-2);
    }

    .mc-ml-3 {
        margin-left: var(--mc-scale-3);
    }

    .mc-ml-2 {
        margin-left: var(--mc-scale-2);
    }

    .mc-pb-5 {
        padding-bottom: var(--mc-scale-5);
    }

    .mc-pb-1 {
        padding-bottom: var(--mc-scale-1);
    }

    .mc-pr-4 {
        padding-right: var(--mc-scale-4);
    }

    .mc-text-small {
        font-size: var(--mc-scale-3-5);
        line-height: 1.45;
        letter-spacing: 0.01em;
    }

    .mc-text-h7 {
        font-size: var(--mc-scale-3-5);
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.03em;
    }

    .mc-opacity--muted {
        opacity: 0.6 !important;
    }
}
