.home {
    position: relative;

    &__hero {
        position: relative;
        margin-bottom: 112px;

        @include mediaSM {
            margin-bottom: 60px;
        }

        @include mediaXS {
            margin-bottom: 43px;
        }


        &-header {
            position: relative;
            border: 1px solid transparent;

            @include mediaSM {
                border: 0;
            }

            h1 {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                @include mediaMD {
                    font-size: 15.3vw;
                    line-height: 13.25vw;
                }
    
                @include mediaSM {
                    position: relative;
                    font-size: 145px;
                    line-height: 128px;
                    margin-left: -3px;
                }
    
                @include mediaXS {
                    font-size: 70px;
                    line-height: 64px;
                    max-width: 400px;
                }
            }
        }
        
        &-grid {
            margin-top: 25%;
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            column-gap: 20px;
            row-gap: 50px;

            @include mediaSM {
                margin-top: 20px;
                flex-direction: column;
                margin-bottom: 59px;
            }

            @include mediaXS {
                // margin-top: 150px;
                row-gap: 40px;
                margin-bottom: 40px;
            }
        }

        &-copy {
            position: relative;
            z-index: 1;
            width: 28%;
            max-width: 320px;
            margin-top: 111px;

            @include mediaSM {
                margin-top: 0;
                width: 70%;
                max-width: unset;
            }

            @include mediaXS {
                max-width: unset;
                width: calc(100% - 35%);
                margin-left: 35%;
            }

            hr {
                margin-bottom: 27px;
                height: 10px;

                @include mediaXS {
                    width: 31px;
                    height: 5px;
                    margin-bottom: 13px;
                }
            }

            p {
                @include mediaXS {
                    font-size: 13px;
                    line-height: 17px;
                }
            }
        }

        &-featured-article {
            position: relative;
            width: 70%;
            max-width: 807px;
            height: 100%;

            @include mediaSM {
                width: 100%;
                max-width: unset;
            }

            @include mediaXS {
                width: 100%;
            }

            .article-hero {
                width: 100%;

                @include mediaSM {
                    float: right;
                    width: 630px;
                    height: 500px;
                }

                @include mediaXS {
                    width: 100%;
                    height: 100%;
                    float: unset;
                }
            }
        }

        
    }

    &__recent-news {
        width: 100%;
        margin-bottom: 148px;

        @include mediaSM {
            margin-bottom: 120px;
        }

        @include mediaXS {
            margin-bottom: 102px;
        }

        &-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: max-content;
            row-gap: 76px;
            column-gap: 58px;
            margin-bottom: 70px;

            @include mediaSM {
                column-gap: 64px;
                margin-bottom: 64px;
            }

            @include mediaXS {
                grid-template-columns: 1fr;
                column-gap: 60px;
                margin-bottom: 54px;
            }
        }

        &-button-row {
            a {
                display: block;
                width: max-content;
                border: 0;
                margin: 0 auto;

                &:hover {
                    border: 0;
                }
            }
        }
    }

    &__about-mc {
        margin-bottom: 184px;

        @include mediaSM {
            margin-bottom: 120px;
        }

        @include mediaXS {
            margin-bottom: 80px;
        }

        &-top-section {
            position: relative;
            max-width: 786px;
            margin: 0 auto;
            margin-bottom: 272px;

            @include mediaSM {
                max-width: 571px;
                margin-bottom: 207px;
            }

            @include mediaXS {
                max-width: unset;
                margin-bottom: 150px;
            }

            .brief-history {
                max-width: 504px;

                hr {
                    margin-bottom: 20px;

                    @include mediaXS {
                        width: 38px;
                        height: 4px;
                        margin-bottom: 10px;
                    }
                }

                .section-title {
                    margin-bottom: 60px;

                    @include mediaXS {
                        font-size: 12px;
                        line-height: 14px;
                        letter-spacing: 1.44px;
                        margin-bottom: 43px;
                    }
                }

                p {
                    font-size: 38px;
                    line-height: 47px;
                    letter-spacing: 0.44px;

                    @include mediaXS {
                        font-size: 20px;
                        line-height: 27px;
                        letter-spacing: 0.2px;
                    }
                }
            }

            .users-worldwide {
                position: absolute;
                max-width: 282px;
                width: 100%;
                top: 260px;
                left: 64%;

                @include mediaSM {
                    top: 300px;
                    left: 49%;
                }

                @include mediaXS {
                    top: 111%;
                    left: 52%;
                    max-width: 142px;
                }


                .counter {
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-family: 'Sohne Schmal';
                    font-size: 290px;
                    line-height: 290px;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;

                    @include mediaXS {
                        font-size: 140px;
                        line-height: 150px;
                        height: 150px;
                    }

                    span {
                        display: block;
                        font-size: 190px;
                        line-height: 104px;
                        width: 67px;
                        margin-top: 20px;

                        @include mediaXS {
                            font-size: 140px;
                            line-height: 150px;
                        }
                    }
                }

                h6 {
                    position: relative;
                    font-weight: 400;
                    font-size: 38px;
                    line-height: 47px;
                    letter-spacing: 0.44px;
                    margin-top: -35px;

                    @include mediaXS {
                        font-size: 19px;
                        line-height: 22px;
                        letter-spacing: 0.2px;
                        margin-top: -13px;
                    }
                }
            }
        }

        &-quote {
            position: relative;
            overflow: hidden;
            margin-left: auto;
            margin-right: auto;
            max-width: 1240px;
            width: 100%;
            min-height: 659px;
            display: flex;
            flex-direction: column;
            gap: 58px;

            @include mediaSM {
                min-height: 786px;
                gap: 34px;
            }

            @include mediaXS {
                min-height: unset;
                height: 570px;
                justify-content: space-between;
            }

            .collage-photo {
                position: absolute;

                img {
                    display: block;
                    border-radius: 0.4rem;
                    width: 170px;
                    height: 170px;
                    object-fit: cover;
                    object-position: center;

                    @include mediaXS {
                        width: 87px;
                        height: 84.53px;
                    }
                }

                &:first-child {
                    top: 0;
                    left: 22%;

                    @include mediaSM {
                        left: 17%;
                    }

                    @include mediaXS {
                        left: 8%;
                    }
                }

                &:nth-child(2) {
                    top: 224px;
                    left: 0;

                    @include mediaSM {
                        top: 377px;
                        left: unset;
                        right: 80%;
                    }

                    @include mediaXS {
                        top: 220px;
                        right: -3%;
                    }
                }

                &:nth-child(3) {
                    top: 184.5px;
                    right: 0;

                    @include mediaSM {
                        top: 338px;
                        right: unset;
                        left: 83%;
                    }

                    @include mediaXS {
                        display: none;
                    }
                }

                &:nth-child(4) {
                    top: 382px;
                    right: 17%;

                    @include mediaSM {
                        top: 562px;
                        right: 22%;
                    }

                    @include mediaXS {
                        top: 370px;
                        right: 50%;
                    }
                }

                &:nth-child(5) {
                    bottom: 0;
                    left: 17%;

                    @include mediaSM {
                        left: 12%;
                    }

                    @include mediaXS {
                        left: unset;
                        right: 80%;
                        bottom: unset;
                        top: 300px;
                    }
                }
            }

            .quote-wrapper {
                max-width: 617px;
                text-align: center;
                margin: 231px auto 0;

                @include mediaSM {
                    max-width: 538px;
                }

                @include mediaXS {
                    max-width: 89%;
                    margin-top: 130px;
                }

                .quote {
                    font-size: 38px;
                    line-height: 47px;
                    letter-spacing: 0.44px;
                    
                    margin-bottom: 16px;

                    @include mediaSM {
                        margin-bottom: 19px;
                    }

                    @include mediaXS {
                        font-size: 28px;
                        line-height: 36px;
                        letter-spacing: 0.2px;
                        margin-bottom: 15px;
                    }
                }

                .quote-author {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 43px;
                    letter-spacing: 1.44px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    @include mediaXS {
                        font-size: 12px;
                        line-height: 17px;
                        letter-spacing: 0.2px;
                        max-width: 150px;
                        flex-direction: column;
                        gap: 1px;
                    }

                    span {
                        display: inline-block;
                        font-weight: 500;

                        span {
                            font-weight: 400;

                            @include mediaXS {
                                display: none;
                            }
                        }
                    }
                }
            }

            .learn-more .btn {
                margin-left: auto;
                margin-right: auto;

                @include mediaXS {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__links {
        margin-bottom: 109px;

        @include mediaSM {
            margin-bottom: 90px;
        }

        a {
            width: max-content;
        }

        .content-grid {
            display: grid;
            grid-template-columns: repeat(2, 483px);
            column-gap: 60px;
            margin: 0 auto;
            max-width: max-content;
            row-gap: 80px;

            @include mediaSM {
                grid-template-columns: repeat(2, 324px);
                column-gap: 39px;
            }

            @include mediaXS {
                grid-template-columns: 325px;
                text-align: center;
            }

            div {
                h5 {
                    font-family: 'Sohne';
                    font-size: 33px;
                    line-height: 30px;
                    letter-spacing: 1.44px;
                    margin-bottom: 20px;

                    @include mediaXS {
                        font-size: 30px;
                        line-height: 36px;
                        margin-bottom: 11px;
                    }
                }

                p {
                    margin-bottom: 30px;

                    @include mediaXS {
                        font-size: 17px;
                        line-height: 21px;
                        letter-spacing: 0.4px;
                        margin-bottom: 25px;
                    }
                }

                .btn {
                    @include mediaXS {
                        margin: 0 auto;
                        font-size: 12px;
                        line-height: 14.06px;
                        letter-spacing: 1.44px;
                    }
                }
            }
        }
    }

    &__media-kit {
        p {
            padding-right: 7%;

            @include mediaSM {
                padding-right: 0;
            }
        }
    }

    &__reach-out {
        p {
            padding-right: 9%;

            @include mediaSM {
                padding-right: 0;
            }
        }

        .btn {
            text-transform: lowercase;
        }
    }
}