.article-card-half {
    position: relative;
    width: 100%;

    &:nth-child(odd) {
        .article-card-half__image {
            height: 340px;

            @include mediaSM {
                height: 190px;
            }

            @include mediaXS {
                height: 187px;
            }
        }
    }

    &:nth-child(even) {
        .article-card-half__image {
            height: 460px;

            @include mediaSM {
                height: 257px;
            }

            @include mediaXS {
                height: 253px;
            }
        }
    }

    &__image {
        cursor: pointer;
        position: relative;
        width: 100%;
        margin-bottom: 16px;

        img {
            height: 100% !important;
            object-fit: cover;
            object-position: center;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            background-color: red;
            background: linear-gradient(180deg, rgba(46, 46, 46, 0.77) 0%, rgba(196, 196, 196, 0) 70.48%);
            mix-blend-mode: lighten;
        }
    }

    &__content {
        .heading {
            cursor: pointer;
            position: relative;
            max-width: 490px;
            font-size: 24px;
            line-height: 31px;
            letter-spacing: 0.2px;
            margin-bottom: 5px;

            @include mediaSM {
                max-width: 324px;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: 0.2px;
            }

            @include mediaXS {
                max-width: 93%;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.42px;
            }
        }

        &.article-quote {
            cursor: pointer;
            position: relative;
            max-width: 457px;
            margin: 0 auto;

            display:flex;
            flex-direction: column;
            justify-content:center;
            height: 100%;

            @include mediaSM {
                max-width: 299px;
            }

            @include mediaXS {
                max-width: 92%;
            }

            .quote {
                margin-bottom: 16px;

                @include mediaSM {
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: 0.44px;
                }

                @include mediaXS {
                    font-size: 23px;
                    line-height: 29px;
                    letter-spacing: 0.2px;
                }
            }

            a {
                padding-bottom: 0;
            }
        }
    }
}

.article-card-half__image:hover + .article-card-half__content .news-link,
.heading:hover ~ .news-link,
.quote:hover ~ .news-link {
    border: 0;

    &.red-underline {
        position: relative;
        border: 0;

        &:hover {
            border: 0;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: -8px;
            width: 100%;
            height: 5px;
            margin: 5px 0 0;
            transition: $animate;
            transition-duration: 0.75s;
            opacity: 1;
            background-color: var(--mc-red);
        }

        .link-arrow {
            transform: translate(2px, -2px);
        }
    }
}
