.header {
    overflow: hidden;
    position: sticky;
    transition: transform 300ms;
    transition-timing-function: ease-out;
    top: -1px;
    background-color: var(--mc-black);
    z-index: 4;
    height: 84px;
    margin-bottom: 70px;
    -webkit-font-smoothing: initial;

    @include mediaSM {
        height: 60px;
        margin-bottom: 60px;
    }

    @include mediaXS {
        margin-bottom: 40px;
    }

    &.press {
        margin-bottom: 0;
    }

    a {
        padding-bottom: 8px;

        &:hover {
            color: var(--mc-red);
            border-bottom: 4px solid var(--mc-red);
        }

        &.active {
            border-bottom: 4px solid var(--mc-red);
        }

        @include mediaSM {
            padding-bottom: 4px;
        }

        @include mediaXS {
            padding-bottom: 3px;
        }
    }

    &__wrapper {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 27px;
        padding-bottom: 21px;

        @include mediaSM {
            padding-top: 17px;
            padding-bottom: 18px;
        }

        @include mediaXS {
            padding-top: 18px;
            padding-bottom: 21px;
        }

        .header__logo {
            cursor: pointer;
            display: flex;
            align-items: flex-end;
            gap: 25px;

            .logo {
                width: 203px;
                height: 30px;

                &.mobile {
                    display: none;
                    width: 34px;
                    height: 18px;
                }
            }

            span {
                display: inline-block;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.42px;
                margin-bottom: 1px;
            }

            @include mediaSM {
                .logo {
                    width: 169px;
                    height: 25px;
                }

                span {
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0.42px;
                }
            }

            @include mediaXS {
                gap: 10px;

                .logo {
                    display: none;

                    &.mobile {
                        display: inline;
                    }
                }

                span {
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.42px;
                }
            }
        }

        nav {
            #hamburger {
                cursor: pointer;
                display: none;

                @include mediaXS {
                    display: block;
                }
            }

            ul {
                height: 100%;

                li {
                    display: inline-block;
                    margin-left: 2.6rem;
                    line-height: 23px;

                    a {
                        font-size: 18px;
                        line-height: 21px;
                        letter-spacing: 0.42px;

                        @include mediaSM {
                            font-size: 16px;
                            line-height: 19px;
                            letter-spacing: 0.42px;
                        }
                    }

                    @include mediaSM {
                        margin-left: 1.2rem;
                    }
                }

                @include mediaXS {
                    display: none;
                }
            }
        }
    }

    &.hide {
        transform: translateY(-150%);
        transition-timing-function: ease-in;
    }
}

.home.mobile-menu__wrapper {
    padding-top: 90px;
    padding-left: 40px;

    nav ul li {
        margin-bottom: 35px;
        font-size: 28px;
        line-height: 33px;
        letter-spacing: 1.44px;
    }

    a {
        &:hover {
            color: var(--mc-red);
            border-bottom: 4px solid var(--mc-red);
        }

        &.active {
            border-bottom: 4px solid var(--mc-red);
        }
    }
}
