.search-form {
    width: 373px;
    height: 50px;
    background: #434343;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 0 20px;

    button {
        background: none;
        border: 0;
        cursor: pointer;
        display: flex;
    }

    img {
        width: 18px;
    }

    input {
        border: 0;
        background: none;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.42px;
        width: 100%;
        height: 100%;
        color: var(--mc-cream);

        &::placeholder {
            color: #C0C0C0;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
    }

    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
    }

    .search-close-icon {
        height: 16px;
        width: 16px;
        cursor: pointer;

        background-image: url('../../assets/svg/form-clear.svg');
        background-size: 14px 14px;
        background-repeat: no-repeat;
    }

    input:placeholder-shown ~ .search-close-icon {
        display: none;
    }
}
