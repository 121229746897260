.contact {
    position: relative;

    .content {
        p {
            margin-bottom: 40px;
            max-width: 658px;

            @include mediaSM {
                max-width: 100%;
            }

            @include mediaXS {
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.4px;
                margin-bottom: 34px;
            }
        }

        .btn-link {
            @include mediaXS {
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.42px;
                max-width: 257px;
            }
        }
    }
}
