.recognition {
    position: relative;
    z-index: 0;

    &__description {
        width: 100%;
        max-width: 687px;
        margin-bottom: 4.8rem;

        @include mediaSM {
            margin-bottom: 3.8rem;
        }

        @include mediaXS {
            margin-bottom: 2.6rem;
        }
    }

    &__awards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        gap: 6rem;

        @include mediaSM {
            gap: 3rem;
        }

        @include mediaXS {
            gap: 2rem;
        }
    }

    &__award {
        position: relative;
        width: 100%;
        height: 100%;
        background: #161616;
        border-radius: 5px;
        overflow: hidden;
        padding: 3rem 3rem 5rem;

        @include mediaSM {
            padding: 3.1rem 2.4rem 3rem;
        }

        @include mediaXS {
            padding: 2rem 1.5rem 1.7rem;
            height: 200px;
            border-radius: 0;
        }

        &:hover {
            .award-statistics {
                transition: opacity 1800ms ease-in-out;
                opacity: 1;

                @include mediaXS {
                    transition: unset;
                    opacity: 0;
                }
            }

            .recognition__award-image {
                transition: opacity 500ms ease-in-out;
                opacity: 0;

                @include mediaXS {
                    transition: unset;
                    opacity: 1;
                }
            }
        }

        &:before {
            @include mediaXS {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 295px;
                z-index: 0;
                background: linear-gradient(1.08deg, #000000 -4.38%, rgba(0, 0, 0, 0) 92.5%, rgba(0, 0, 0, 0) 92.51%);
                border-radius: 0;
            }
        }

        &:after {
            @include mediaXS {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 149px;
                z-index: 0;
                border-radius: 0;
                background: linear-gradient(179.75deg, rgba(30, 30, 30, 0) 0.22%, #1E1E1E 82.31%);
            }
        }

        &-content {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-image {
            transition: opacity 1.5s ease-in-out;
            position: absolute;
            opacity: 1;

            &.award-logo {
                z-index: 1;
                display: flex;
                align-items: center;
                top: 30%;
                left: 50%;
                transform: translate(-50%, -30%);
                height: 175px;
                width: 100%;
                max-width: 330px;
                padding-left: 5%;
                padding-right: 5%;
                mix-blend-mode: exclusion;

                @include mediaSM {
                    max-width: 200px;
                }

                img {
                    width: 100%;
                    height: auto;
                    object-fit: scale-down;
                    object-position: center;
                }
            }

            &.award-background {
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 295px;
                    z-index: 1;
                    background: linear-gradient(1.08deg, #000000 -4.38%, rgba(0, 0, 0, 0) 92.5%, rgba(0, 0, 0, 0) 92.51%);
                    border-radius: 0 0 5px 5px;

                    @include mediaXS {
                        display: none;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 149px;
                    z-index: 1;
                    background: linear-gradient(1.08deg, #161616 -4.38%, rgba(0, 0, 0, 0) 92.5%, rgba(0, 0, 0, 0) 92.51%);
                    border-radius: 0 0 5px 5px;

                    @include mediaXS {
                        display: none;
                    }
                }
            }


        }

        .award-statistics {
            transition: opacity 500ms ease-in-out;
            display: grid;
            grid-template-columns: repeat(2, auto);
            column-gap: 4rem;
            row-gap: 5rem;
            font-size: 1.6rem;
            line-height: 2rem;
            letter-spacing: 0.2px;
            margin-bottom: 4rem;
            opacity: 0;

            @media (max-width: 1200px) {
                grid-template-columns: repeat(1, auto);
                row-gap: 4rem;
            }

            @include mediaSM {
                row-gap: 2.2rem;
                text-align: center;
                font-size: 1.4rem;
            }

            @include mediaXS {
                display: none;
            }

            &__header {
                font-size: 1.2rem;
                line-height: 1.4rem;
                letter-spacing: 1.44px;
                text-transform: uppercase;
                color: #919191;
                margin-bottom: 0.7rem;

                @include mediaSM {
                    font-size: 1rem;
                    line-height: 1.2rem;
                    margin-bottom: 0.4rem;
                }
            }
        }

        .award-year-won {
            width: 100%;
            max-width: 140px;

            @include mediaSM {
                max-width: unset;
            }
        }

        .award-category {
            width: 100%;
            max-width: 231px;

            @include mediaSM {
                max-width: unset;
            }
        }

        .award-executive {
            width: 100%;
            max-width: 140px;

            @include mediaSM {
                max-width: unset;
            }
        }

        .award-title {
            position: relative;
            z-index: 1;
            font-size: 2rem;
            line-height: 2.5rem;
            letter-spacing: 1.4px;
            text-align: center;
            width: 100%;
            max-width: 280px;
            margin-left: auto;
            margin-right: auto;

            @include mediaSM {
                font-size: 1.6rem;
                line-height: 2.2rem;
                max-width: 200px;
            }

            @include mediaXS {
                font-size: 1rem;
                line-height: 1.4rem;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                max-width: 150px;
            }
        }
    }

    &__mobile-menu {
        padding-top: 10.1rem;
        padding-left: 4.4rem;
        padding-right: 4.4rem;
        text-align: center;

        font-size: 1.8rem;
        line-height: 2.6rem;
        letter-spacing: 0.2px;

        .award-title {
            position: relative;
            z-index: 1;
            font-size: 2.5rem;
            line-height: 3rem;
            letter-spacing: 1.4px;
            width: 100%;
            max-width: 280px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5rem;
        }

        .award-statistics__header {
            font-size: 1.2rem;
            line-height: 1.4rem;
            letter-spacing: 1.44px;
            text-transform: uppercase;
            color: #787878;
            margin-bottom: 1rem;
        }

        .award-year-won {
            width: 100%;
            max-width: 140px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 4.5rem;
        }

        .award-category {
            width: 100%;
            max-width: 270px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 4.5rem;
        }

        .award-executive {
            width: 100%;
            max-width: 270px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
